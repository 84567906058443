<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="location.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('location.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('location.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>
        
        <div class="container pl-0">
        <b-row>
          <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
            <b-form-group :label="$t('location.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
              <b-input-group>
                <b-form-input id="name" type="text"
                  :data-vv-as="$t('location.field.name')"
                  data-vv-name="location.name"
                  data-vv-delay="500"
                  v-model="location.name" 
                  v-validate="{ required: true }"
                  :readonly="isNameReadOnly"
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'location.name')"
                  autofocus trim>
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('location.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
            <b-form-group :label="$t('field.identifier')" label-for="identifier">
              <b-input-group>
                <b-form-input id="identifier" type="text"
                  :data-vv-as="$t('field.identifier')"
                  data-vv-name="location.identifier"
                  :maxlength="maxIdentifierLength"
                  v-model="location.identifier" 
                  :readonly="isIdentifierReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['identifier'] != null">
            <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col v-if="isStreetVisible" cols="12" md="8" class="pr-0">
            <b-form-group :label="$t('location.field.street')" label-for="street">
              <b-input-group>
                <b-form-input id="street" type="text"
                  v-model="location.street" 
                  :readonly="isStreetReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['street'] != null">
            <b-col v-for="(field, index) in customFieldMap['street']" :key="'street'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
            <div class="color-container">
              <Color :disabled="isColorReadOnly" v-model="location.color" :update="updatedColor"/>
            </div>
          </b-col>

          <template v-if="customFieldMap['color'] != null">
            <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        
          <b-col cols="12" class="pr-0" v-if="isTownVisible">
            <b-form-group :label="$t('location.field.town')" label-for="town">
              <b-input-group>
                <b-form-input id="town" type="text"
                  v-model="location.town" 
                  :readonly="isTownReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['town'] != null">
            <b-col v-for="(field, index) in customFieldMap['town']" :key="'town'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0" v-if="isRegionVisible">
            <b-form-group :label="$t('location.field.region')" label-for="region">
              <b-input-group>
                <b-form-input id="region" type="text"
                  v-model="location.region" 
                  :readonly="isRegionReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['region'] != null">
            <b-col v-for="(field, index) in customFieldMap['region']" :key="'region'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0" v-if="isPostcodeVisible">
            <b-form-group :label="$t('location.field.postcode')" label-for="postcode">
              <b-input-group>
                <b-form-input id="postcode" type="text"
                  v-model="location.postcode" 
                  :readonly="isPostcodeReadOnly"
                  trim>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['postcode'] != null">
            <b-col v-for="(field, index) in customFieldMap['postcode']" :key="'postcode'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0" v-if="isCountryCodeVisible">
            <b-form-group :label="$t('location.field.country')" label-for="countryCode">
              <b-input-group>
                <b-form-select id="countryCode"  :options="optionCountry"
                  v-model="location.countryCode" 
                  :disabled="isCountryCodeReadOnly"
                  trim>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['country'] != null">
            <b-col v-for="(field, index) in customFieldMap['country']" :key="'country'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0" v-if="isRebateVisible">
            <b-form-group>
              <label class="mr-1">{{ $t(`task.field.rebates`) }}</label>
              <button v-if="!isRebateReadOnly" id="REBATE_ADD" class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="REBATE_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('project.button.rebate_add')">
                </b-popover>
              </button>

              <b-form-text class="rebate-total mr-1">
                    {{ $t(`task.field.total_rebate`, [totalRebate]) }}
              </b-form-text>
              <BadgeGroup v-model="rebates" :readOnly="isRebateReadOnly">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
                    :text="`${item.name} (${formatRebate(item.rebate)}%)`" 
                    variant="primary" 
                    :pillable="!!item.pillable" :key="index"
                    :readOnly="isReadOnly" />
                  </template>
              </BadgeGroup>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['rebates'] != null">
            <b-col v-for="(field, index) in customFieldMap['rebates']" :key="'rebates'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" class="pr-0" v-if="isTagVisible">
            <b-form-group>
              <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['tags'] != null">
            <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" class="pr-0" v-if="isCalendarVisible">
            <b-form-group>
              <label class="mr-2">{{ $t('location.field.calendar') }}</label>
              <button  @click="showCalendar()" class="btn-action btn-lg"><font-awesome-icon :icon="['fad', 'calendar-days']"/></button>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['calendar'] != null">
            <b-col v-for="(field, index) in customFieldMap['calendar']" :key="'calendar'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['notes'] != null">
            <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="location[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          </b-row>
        </div>
      </template>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="viewStaff" style="margin-right: auto" v-if="exists && canView('STAFF')">
          {{ $t('button.view_staff') }}
        </b-button>
        <b-button v-if="!isAccessDenied && ((exists && canEdit()) || (!exists && canAdd()))" :disabled="disableOk" size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <CalendarModal :id="id" :calendar="calendar" calendarName="location" :calendarOrder="calendarOrder" :title="calendarTitle" :show.sync="state.calShow" @ok="calOk" :readOnly="isReadOnly" />
    
    <!-- rebate selector -->
    <GenericSelectorModalForAdmin v-if="state.rebateSelectorShow"
      :show.sync="state.rebateSelectorShow" 
      :entityService="rebateUtil" 
      entity="REBATE"
      nonAdmin
      :preselected="rebateEdit.uuId"
      @ok="rebateSelectorOk"
    />

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="LOCATION" :customFields="customFields" links="CALENDAR,NOTE,REBATE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
      <!-- staff selector -->
      <StaffSelectorModalForAdmin v-if="showStaffSelector"
        :show.sync="showStaffSelector" 
        :location="location"
        nonAdmin
        hideOkBtn
      />
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { processCalendar, strRandom, processRegExp } from '@/helpers';
import { updateRebates } from '@/helpers/rebate';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';

import { toFixed } from '@/helpers/task-duration-process';
import { locationService, locationLinkRebateService,
         locationLinkTagService } from '@/services';
import { countryCodes } from "@/selectOptions";
import CalendarModal from '@/components/modal/CalendarModal';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { rebateUtil } from '@/views/management/script/rebate';

export default {
  name: 'LocationModal',
  components: {
    CalendarModal,
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    id:        { type: String,   default: `LOCATION_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'LOCATION',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      showStaffSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false,
        calShow:             false,
        historyShow:         false,
        noteShow:            false,
        rebateSelectorShow: false,
      },
      location: {
        uuId:               null,
        name:               null,
        countryCode:        null,
        region:             null,
        town:               null,
        street:             null,
        postcode:           null,
        identifier:         null,
        color:              null,
        readOnly:           false
      },
      optionCountry: countryCodes,
      calendar: {},
      calendarOrder: ["location", "base_calendar"],
      tags: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      rebates: [],
      rebateEdit: {
        uuId: null,
        name: null,
        rebate: null
      },
      
      updatedColor: null,
      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},
      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    if(this.id && this.id.indexOf('LOCATION_NEW_') == -1) {
      this.locationGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originLocation = null;
    this.originRebates = [];
    this.originTags = [];
    this.originNotes = [];
    this.rebateUtil = rebateUtil;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originLocation = null;
    this.originRebates = null;
    this.originTags = null;
    this.originNotes = null;
    this.rebateUtil = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `LOCATION_FORM_${this.id}`;
    },
    exists() {
      return this.id && !this.id.startsWith('LOCATION_NEW_');
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.location.readOnly || this.$store.state.epoch.value !== null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'location.name');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title : this.$t('location.title_new');
    },
    calendarTitle() {
      return this.exists? this.$t('calendar.title_edit') : this.$t('calendar.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['identifier']) && this.canView(this.permissionName, ['identifier']))
      || (this.exists && this.canView(this.permissionName, ['identifier']))
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['color']) && this.canView(this.permissionName, ['color'])) 
      || (this.exists && this.canView(this.permissionName, ['color']))
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isRebateVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('REBATE') && this.canView(this.permissionName, ['REBATE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['REBATE'])) || this.exists)
    },
    isRebateReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['REBATE'])
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isStreetVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['street']) && this.canView(this.permissionName, ['street']))
      || (this.exists && this.canView(this.permissionName, ['street']))
    },
    isStreetReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['street']))
    },
    isTownVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['town']) && this.canView(this.permissionName, ['town']))
      || (this.exists && this.canView(this.permissionName, ['town']))
    },
    isTownReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['town']))
    },
    isRegionVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['region']) && this.canView(this.permissionName, ['region']))
      || (this.exists && this.canView(this.permissionName, ['region']))
    },
    isRegionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['region']))
    },
    isPostcodeVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['postcode']) && this.canView(this.permissionName, ['postcode']))
      || (this.exists && this.canView(this.permissionName, ['postcode']))
    },
    isPostcodeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['postcode']))
    },
    isCountryCodeVisible() {
      return (!this.exists && this.canAdd(this.permissionName, ['countryCode']) && this.canView(this.permissionName, ['countryCode']))
      || (this.exists && this.canView(this.permissionName, ['countryCode']))
    },
    isCountryCodeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['countryCode']))
    },
    isCalendarVisible() {
      return this.canView('CALENDAR') && this.canView(this.permissionName, ['CALENDAR']);
    },
    totalRebate() {
      var total = 0;
      for (const rebate of this.rebates) {
        total += rebate.rebate;
      }
      return toFixed(total*100, 2);
    },
    disableOk() {
      return (this.original.readOnly && this.location.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.state.modalShow) {
        await getCustomFieldInfo(this, 'LOCATION');
        this.$validator.resume();
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.notes = [];
        this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        if(this.id.indexOf('LOCATION_NEW_') === -1) {
          this.locationGet(this.id);
        } else {
          if (newValue) {
            const requiredFields = ['name']
            const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (requiredCustomFields.length > 0) {
              requiredFields.push(...requiredCustomFields);
            }
            let result = this.canView2(this.permissionName, requiredFields);
            if (result.val) {
              result = this.canAdd2(this.permissionName, requiredFields)
            } 
            
            if (result.restrictedProp != null) {
              this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isAccessDenied = false;
            } else {
              this.isAccessDenied = true;
            }
          } else {
            this.isAccessDenied = false;
          }
          this.resetLocationProperties();
        }
      }
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`location.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "LOCATION"}).then(value => {
        self.modelInfo = value.LOCATION.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    hasFieldValidationError(key) {
      return this.errors.first(key) && this.errors.first(key).length? true: false;
    },
    // Form field validation
    fieldStateValidate(ref) {
      if (!this.readOnly) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)? null: false;
        }
      }
      return null;
    },
    fieldInvalidFeedback(ref) {
      const matchedErrors = this.errors.items.filter(i => i.field == ref);
      if(matchedErrors.length > 0) {
        return matchedErrors[0].msg;
      } else {
        return '';
      }
    },
    locationGet(id) {
      locationService.query([{ uuId: id }], ['TAG', 'NOTE', 'REBATE']).then(response => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.location;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }
      
      this.original.readOnly = data.readOnly;

      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      this.originLocation = cloneDeep(s);
      
       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      
      // Setup rebates data
      if (data.rebateList && data.rebateList.length > 0) {
        const list = data.rebateList;
        this.rebates = cloneDeep(list);
        this.originRebates = cloneDeep(list);
      }
      else {
        this.rebates = [];
        this.originRebates = [];
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }
      else {
        this.tags = [];
        this.originTags = [];
      }
      
    },
    modalOk() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.location[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      //Cross field validation
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.locationSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    locationSubmit() {
      const data = cloneDeep(this.location);
      
      let mode = 'update';
      if(this.id.indexOf('LOCATION_NEW_') !== -1) {
        mode = 'create';
        delete data['uuId'];
      }
      this.locationPost(mode, data, this.$t(`location.${mode}`));
    },
    async locationPost(method, data, successMsg) {
      this.state.isSubmitting = true;

      //Skip updating location if there is no change in location properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedLocationProperties(data);
      }
      
      let result = null;
      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        result = await locationService[method]([data])
        .then((response) => {
          const data = response.data;
          return { uuId: data[data.jobCase][0].uuId };
        })
        .catch(e => {
           return { hasError: true, error: e }
        });

        if (result != null && (result.hasError || result.uuId == null)) {
          this.httpAjaxError(result.error);
          this.state.isSubmitting = false;
          return;
        }
        
        if (method === 'create') {
          data.uuId = result;
        }
      }

      let hasError = false;

      // save the color in the profile
      this.updatedColor = data.color;
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(data.uuId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`location.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      if (!this.isRebateReadOnly) {
        const rebateResult = await updateRebates(data.uuId, locationLinkRebateService, this.originRebates, this.rebates)
        if (rebateResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (rebateResult.errorCodes.length > 0) {
            if (rebateResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('rebate.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(data.uuId, locationLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`location.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      this.state.isSubmitting = false;
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg });
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `location.${feedback.args[0]}`;
          const args = [this.$t(`location.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetLocationProperties() {
      const keys = Object.keys(this.location);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.location[keys[i]] = customField.def;
            continue;
          }
        }
        this.location[keys[i]] = null;
      }
      this.originLocation = null;
      this.tags = [];
      this.originTags = [];
      this.originNotes = [];
      this.notes = [];
      this.originRebates = [];
      this.rebates = [];
    },
    showCalendar() {
      if(this.id && this.id.indexOf('LOCATION_NEW_') !== -1) {
        this.alertMsg = "Please save location before editing calendar";
        return;
      }
      
      const self = this;
      locationService.calendar(this.id)
      .then((response) => {
        // combine the calendar lists into single lists for each day
        const data = response.data[response.data.jobCase];
        self.calendar = processCalendar(data);

        self.state.calShow = true;
      })
      .catch((e) => {
        self.httpAjaxError(e);
      });
    },
    calOk() {
    
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedLocationProperties(data) {
      //Remove those properties whose value is not changed in provided data against original location.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalLocation = this.originLocation;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalLocation[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    
    rebateSelectorToggle() {
      this.rebateEdit.uuId = null;
      this.state.rebateSelectorShow = true;
    },
    rebateSelectorOk({details}) {
      const rebates = JSON.parse(JSON.stringify(this.rebates));
      if (details.length > 0) {
        const newRebatesToReplacePreselected = [];
        for (const r of details) {
          const foundIndex = rebates.findIndex(i => i.uuId == r.uuId);
          if (foundIndex > -1) {
            const rebate = rebates[foundIndex];
            rebate.uuId = r.uuId;
            rebate.name = r.name;
            rebate.rebate = r.rebate;
            newRebatesToReplacePreselected.push(rebate);
            rebates.splice(foundIndex, 1);
          } else {
            newRebatesToReplacePreselected.push({ uuId: r.uuId, name: r.name, rebate: r.rebate })
          }
        }
        
        if (this.rebateEdit.uuId != null) {
          const foundIndex = rebates.findIndex(i => i.uuId == this.rebateEdit.uuId);
          if (foundIndex != -1) {
            rebates.splice(foundIndex, 1, ...newRebatesToReplacePreselected);
          } else {
            rebates.push(...newRebatesToReplacePreselected);
          }
        } else {
          rebates.push(...newRebatesToReplacePreselected);
        }
      } else if (this.rebateEdit.uuId != null) {
        //When no selection is made, remove the preselected one from the existing rebates
        rebates.splice(rebates.findIndex(j => j.uuId === this.rebateEdit.uuId), 1);
      }
      this.rebates.splice(0, this.rebates.length, ...rebates);

      this.rebateEdit.uuId = null;
    },
    rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    },
    rebateBadgeClick(id) {
      this.rebateEdit.uuId = id;
      this.state.rebateSelectorShow = true;
    },
    formatRebate(rebate) {
      return toFixed(rebate*100, 2);
    },
    viewStaff() {
      this.showStaffSelector = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>

<style lang="scss" scoped>
.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

</style>
